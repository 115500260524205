import React from 'react';
import {Divider} from '../../components/divider/divider';
import {AboutUs} from './about-us/about-us';
import {Coworkers} from './coworkers/coworkers';
import {Growth} from './growth/growth';
import {HowWeWork} from './how-we-work/how-we-work';
import {ItCommunity} from './it-community/it-community';
import {ManyProjects} from './many-projects/many-projects';
import {MoreProfit} from './more-profit/more-profit';
import {OurClients} from './our-clients/our-clients';
import {TrustedBy} from './trusted-by/trusted-by';
import {VideoSection} from './video/video';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

export const HomePage = () => {
  const {
    restApiHomePage: {seo},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
      </Helmet>
      <VideoSection />
      <TrustedBy />
      <Divider className={'phone'} />
      <AboutUs />
      <Divider className={'phone'} />
      <ItCommunity />
      <Divider />
      <Growth />
      <Coworkers />
      <MoreProfit />
      <HowWeWork />
      <Divider />
      <ManyProjects />
      <Divider />
      <OurClients />
      {/* <Divider />
      <Partnership /> */}
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        seo {
          title
          description
        }
      }
    }
  `);
