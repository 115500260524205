import React from 'react';
import '../main.scss';
import {Layout} from '../components/layout/layout';
import {HomePage} from '../pages-components/homepage/homepage';

export default function Home(): React.ReactNode {
  return (
    <Layout>
      <HomePage />
    </Layout>
  );
}
