import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';

import st from './it-community.module.scss';

export const ItCommunity = () => {
  const {
    restApiHomePage: {
      it_community: {title, description, image},
    },
  } = query();
  return (
    <div className={st.section}>
      <div className={st.content}>
        <div className={st.content__asset}>
          <video
            className={st.asset}
            src={image.url}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          ></video>
        </div>
        <div className={st.content__text}>
          <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(description)}></div>
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        it_community {
          description
          image {
            url
            name
          }
          title
          button {
            text
            url
          }
        }
      }
    }
  `);
