import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {Button} from '../../../components/button/button';
import {mark} from '../../../utils/marked';
import st from './more-profit.module.scss';

export const MoreProfit = () => {
  const {
    restApiHomePage: {
      more_profit: {title, description, button, image},
    },
  } = query();

  return (
    <div className={st.section}>
      <div className={st.content}>
        <div className={st.content__text}>
          <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(description)}></div>
        </div>
        <div className={st.content__asset}>
          <div className={st.asset} style={{backgroundImage: `url(${image.url})`}}></div>
        </div>
      </div>
      <div className={st.button}>
        <Button to={button.url}>{button.text}</Button>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        more_profit {
          title
          description
          button {
            url
            text
          }
          image {
            url
          }
        }
      }
    }
  `);
