import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './about-us.module.scss';

export const AboutUs = () => {
  const {
    restApiHomePage: {
      info_section: {info_description, info_header},
    },
  } = query();

  return (
    <div className={st.section}>
      <div className={`${st.title} ${st.h1}`} dangerouslySetInnerHTML={mark(info_header)}></div>
      <div className={st.subtitle} dangerouslySetInnerHTML={mark(info_description)}></div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        info_section {
          info_description
          info_header
        }
      }
    }
  `);
