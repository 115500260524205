import React from 'react';
import st from './our-clients.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1200},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 1199, min: 0},
    items: 1,
  },
};

export const OurClients = () => {
  return (
    <div className={st.section}>
      <h2 className={st.title}>
        Our IT Experts <strong>Have Worked On</strong>
      </h2>
      <Carousel
        responsive={responsive}
        ssr={true}
        infinite={true}
        removeArrowOnDeviceType={['mobile']}
        className={st.carousel}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {Clients.map(client => (
          <ClientCard client={client} key={client.id} />
        ))}
      </Carousel>
    </div>
  );
};

const Clients = [
  {
    header:
      'An advanced solution that provides valuable insights to Marketing Directors in form of beautiful dashboards.',
    description: 'The scope of work included: UX, design, and front-end development.',
    logos: ['/angular.svg', '/javascript.svg', '/bootstrap.svg'],
    image: '/experts_1.png',
  },
  {
    header: 'Our professionals played a key role in the project. ',
    description:
      'The scope of work included: creating the solution’s architecture, infrastructure, and DevOps strategy, back-end, front-end development, ML, databases.',
    logos: ['/net.svg', '/angular.svg', '/tech_4.svg', '/e.svg'],
    image: '/experts_2.png',
  },
  {
    header: 'Working on solutions to the non-standard needs of the client from the film industry. ',
    description:
      'The scope of work included: selecting the technology, preparing project specifications, delivering a tailor-made application. ',
    logos: ['/tech_1.svg', '/node.svg', '/javascript.svg'],
    image: '/experts_3.png',
  },
  {
    header:
      'The ambitious project was to deliver several different banking systems and custom applications to a number of 28 banks.',
    description: 'The scope of work included: taking over the iOS area of the product’s development.',
    logos: ['/apple.svg', '/tech_2.svg'],
    image: '/experts_4.png',
  },
  {
    header:
      'During our long-term cooperation, our developers took full responsibility to deliver customer-facing web applications and websites.',
    description: 'The scope of work included: taking full ownership of the delivered projects.',
    logos: ['/react.svg', '/firebase.svg', '/javascript.svg', '/tech_3.svg'],
    image: '/experts_5.png',
  },
  {
    header:
      'The app built for the German premium automotive brand consisted of 20 different modules. We helped one of those projects with our talented development team.',
    description: 'The scope of work included: front-end and back-end development.',
    logos: ['/java.svg', '/aws.svg', '/angular.svg', '/bootstrap.svg'],
    image: '/experts_6.png',
  },
];
const ClientCard = ({client}) => {
  const {description, header, logos, image} = client;
  return (
    <div className={st.card}>
      <div className={st.card__image}>
        <img src={image} alt={image} />
      </div>
      <div className={st.card__content}>
        <div className={st.card__header}>{header}</div>
        <p className={st.card__desc}>{description}</p>
        <div className={st.card__wrap}>
          <p className={st.card__tech}>TECH STACK</p>
          <div className={st.card__logo}>
            {logos.map(logo => (
              <img key={logo} className={st.card__logo__img} src={logo} alt={logo} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
