import {graphql, Link, useStaticQuery} from 'gatsby';
import React, {RefObject, useEffect, useRef, useState} from 'react';
import st from './video.module.scss';
import {mark} from '../../../utils/marked';

export const VideoSection = () => {
  const {
    restApiHomePage: {
      video_section: {
        clients_description,
        clients_header,
        clients_video,
        clients_video_image,
        specialists_description,
        specialists_header,
        specialists_video,
        specialists_video_image,
      },
    },
  } = query();

  const clientsVideoRef = useRef() as RefObject<HTMLVideoElement>;
  const specialistsVideoRef = useRef() as RefObject<HTMLVideoElement>;

  const [focusedVideo, setFocusedVideo] = useState(clientsVideoRef);

  useEffect(() => {
    focusedVideo.current.pause();
    clientsVideoRef.current.pause();
    clientsVideoRef.current.loop = true;
    clientsVideoRef.current.muted = true;
    clientsVideoRef.current.playsInline = true;
    clientsVideoRef.current.play();
    specialistsVideoRef.current.loop = true;
    specialistsVideoRef.current.muted = true;
    specialistsVideoRef.current.playsInline = true;
  }, []);

  const handleFocusVideoChange = videoRef => {
    focusedVideo.current.pause();
    setFocusedVideo(videoRef);
    videoRef.current.play();
  };

  return (
    <div className={st.video__section}>
      <div className={st.video__container}>
        <div className={st.video} onMouseEnter={() => handleFocusVideoChange(clientsVideoRef)}>
          <video src={clients_video.url} ref={clientsVideoRef}>
            <img src={clients_video_image.url} alt='Conversation of client and company' />
          </video>
          <div className={`${st.video__overlay} ${focusedVideo === clientsVideoRef ? st.hidden : ''}`} />
        </div>
        <div className={st.video} onMouseEnter={() => handleFocusVideoChange(specialistsVideoRef)}>
          <video src={specialists_video.url} ref={specialistsVideoRef}>
            <img src={specialists_video_image.url} alt='Keyboard of laptop' />
          </video>
          <div className={`${st.video__overlay} ${focusedVideo === specialistsVideoRef ? st.hidden : ''}`} />
        </div>
      </div>
      <Link to='/clients'>
        <div className={`${st.button} ${st.button__left}`}>
          <div>
            <div className={st.button__title} dangerouslySetInnerHTML={mark(clients_header)} />
            <p className={st.button__subtitle}>{clients_description}</p>
          </div>
        </div>
      </Link>
      <Link to='/community'>
        <div className={`${st.button} ${st.button__right}`}>
          <div>
            <div className={st.button__title} dangerouslySetInnerHTML={mark(specialists_header)} />
            <p className={st.button__subtitle}>{specialists_description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        id
        video_section {
          clients_description
          clients_header
          clients_video {
            url
          }
          clients_video_image {
            url
          }
          specialists_description
          specialists_header
          specialists_video {
            url
          }
          specialists_video_image {
            url
          }
        }
      }
    }
  `);
