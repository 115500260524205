import React from 'react';
import {Button} from '../../../components/button/button';
import {URLS} from '../../../components/nav/nav';
import st from './how-we-work.module.scss';

export const HowWeWork = () => {
  return (
    <>
      <div className={st.section__dark}>
        <div className={st.section__image} style={{backgroundImage: `url('/how-we-work-bg.svg')`}}>
          <div className={st.section__wrapper}>
            <div className={st.content}>
              <p className={st.title}>
                How <strong>We Work</strong>
              </p>
              <p className={st.desc}>
                One of our ways to make the most out of the software developers’ and other IT specialists' working time
                is to ensure they remain highly motivated. We do that by estimating the real needs of the project.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={st.section}>
        <div className={st.section__wrapper}>
          <div className={st.cards}>
            <div className={st.card}>
              <div className={st.card__wrapper}>
                <div className={st.card__header}>
                  <p className={st.card__title}>Staff Augumentation</p>
                  <div className={st.card__image}>
                    <img src={'/augumentation.svg'} />
                  </div>
                </div>

                <p className={st.card__text}>
                  Choose from Europe's most talented individuals available in our Blue House Community. They will boost
                  your project development and share knowledge across the organization.
                </p>
              </div>
            </div>
            <div className={st.card}>
              <div className={`${st.card__wrapper} ${st.card__wrapper_second}`}>
                <div className={st.card__header}>
                  <p className={st.card__title}>Dedicated Team</p>
                  <div className={st.card__image}>
                    <img src={'/team.svg'} />
                  </div>
                </div>

                <p className={st.card__text}>
                  Share with us your project requirements and we will build a complete team of IT Experts. The skills of
                  the selected specialists complement each other, which increases the efficiency of work.
                </p>
              </div>
            </div>
          </div>

          <Button stClassName={st.button} to={URLS.CLIENTS}>
            Read more
          </Button>
        </div>
      </div>
    </>
  );
};
