import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './many-projects.module.scss';

export const ManyProjects = () => {
  const {
    restApiHomePage: {
      projects: {title, subtitle, image},
    },
  } = query();

  return (
    <>
      <div className={st.section}>
        <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
        <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)}></div>
      </div>
      <div className={st.content__asset}>
        <div className={st.asset} style={{backgroundImage: `url(${image.url})`}}></div>
      </div>
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        projects {
          title
          subtitle
          image {
            url
          }
        }
      }
    }
  `);
