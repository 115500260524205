import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStaticQuery, graphql} from 'gatsby';
import React, {useEffect, useState} from 'react';

import st from './trusted-by.module.scss';

export const TrustedBy = () => {
  const {
    restApiHomePage: {
      trusted_by_section: {opinions = [], title},
    },
  } = query();
  let interval;

  const [opinionIndex, setOpinionIndex] = useState(0);

  useEffect(() => {
    setOpinionChangeInterval();

    return () => {
      clearInterval(interval);
    };
  }, [opinionIndex]);

  const setOpinionChangeInterval = () => {
    interval = window.setInterval(() => {
      if (opinionIndex < opinions.length - 1) {
        setOpinionIndex(opinionIndex + 1);
      } else {
        setOpinionIndex(0);
      }
    }, 3000);
  };

  const setCurrentOpinion = (index: number) => {
    clearInterval(interval);
    setOpinionIndex(index);
  };

  return (
    <div className={st.section}>
      <div className={st.section__container}>
        <div className={st.logos}>
          <p className={st.logos__title}>{title}</p>
          <div className={st.logos__container}>
            {opinions.map(({brand_logo}, index) => (
              <img
                className={`${st.logo} ${opinionIndex === index ? st.logo__active : ''}`}
                onMouseEnter={() => setCurrentOpinion(index)}
                key={index}
                src={brand_logo.url}
                alt={brand_logo.name}
              />
            ))}
          </div>
        </div>
        <div className={st.opinion}>
          {opinions.map(({avatar, name, opinion, linkedin_url}, index) => (
            <div
              className={`${st.opinion__container} ${opinionIndex === index ? st.opinion__container__active : ''}`}
              key={index}
            >
              <div className={st.image} style={{backgroundImage: `url(${avatar.url})`}}></div>
              <div className={st.content}>
                <p className={st.text}>{opinion}</p>
                <p className={st.name}>
                  {name}{' '}
                  <span className={st.icon}>
                    <a href={linkedin_url} target='_blank' rel='noreferrer'>
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        trusted_by_section {
          opinions {
            name
            opinion
            avatar {
              url
              name
            }
            brand_logo {
              url
              name
            }
            linkedin_url
          }
          title
        }
      }
    }
  `);
