import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {Button} from '../../../components/button/button';
import {mark} from '../../../utils/marked';

import st from './growth.module.scss';

export const Growth = () => {
  const {
    restApiHomePage: {
      growth: {title, subtitle, image, button},
    },
  } = query();
  return (
    <div className={st.section}>
      <div className={st.content}>
        <div className={st.content__text}>
          <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)}></div>
          <Button stClassName={st.desktop} to={button.url}>
            {button.text}
          </Button>
        </div>
        <div className={st.content__asset}>
          <video
            className={st.asset}
            src={image.url}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          ></video>
        </div>
        <Button stClassName={st.mobile} to={button.url}>
          {button.text}
        </Button>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        growth {
          subtitle
          image {
            url
          }
          title
          button {
            text
            url
          }
        }
      }
    }
  `);
